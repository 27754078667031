import  { Suspense, lazy, useEffect} from 'react';
import './App.css';
import axios from 'axios';
import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { AdapterDayjs as DateAdapter } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
// import { DatePicker, LocalizationProvider } from '@mui/lab';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';

// react-query setup
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// My components
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import AuthRoute, {
    TokenRoute,
    WithSmithAIRoute,
    WithoutSmithAIRoute,
    LoginRequriedRoute,
} from './components/CustomRoutes';
import IdleTimer from './components/IdleTimer';
import themeFile from './util/theme';

// Landing Pages
import LoadingPage from './pages/UtilityPages/LoadingPage';
import FeedBack from './components/Gadgets/UserFeedBack';
import { ErrorPage } from './pages/UtilityPages/ErrorBoundary';
import { loginUserFromLastSession } from './redux/actions/userAction';

import { LicenseInfo } from '@mui/x-license-pro';

import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '',
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);


LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY);

// Lazy loading
const LoginPage = lazy(() => import('./pages/LoginPage'));
const ForgetPasswordPage = lazy(() => import('./pages/UtilityPages/ForgetPasswordPage'));
const ResetPasswordPage = lazy(() => import('./pages/UtilityPages/ResetPasswordPage'));
const PortalHomePage = lazy(() => import('./pages/PortalPages/PortalHomePage'));
const PageNotFound = lazy(() => import('./pages/UtilityPages/404Page'));
const AccountSettingsPage = lazy(() => import('./pages/PortalPages/AccountSettingsPage'));
const UserManagementPage = lazy(() => import('./pages/PortalPages/UserManagementPage'));
const CRFFormPage = lazy(()=> import('./pages/FormPages/CRFFormPage'));
const ECrfFlatFile = lazy(()=> import('./pages/PortalPages/eCrfFlatFile'));
const EditInstitutionPage = lazy(()=> import('./pages/PortalPages/EditInstitutionPage'));
const ClinicalProtocolsManagementPage = lazy(()=> import('./pages/PortalPages/ClinicalProtocolsManagementPage'));
const DailyPatientsPage = lazy(()=> import('./pages/PortalPages/DailyPatientsPage'));
const MessagesPage = lazy(()=> import('./pages/PortalPages/MessagesPage'));

const AdminInstitutionPage = lazy(()=> import('./pages/PortalPages/AdminInstitutionPage'));

export const queryClient =  new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
            staleTime: 10 * 1000, // default stale time is 10 seconds
        }
    }
});

const theme = createTheme(themeFile);

// Axios setup
axios.defaults.baseURL = process.env.REACT_APP_AXIOS_URL || "";

// Set interceptors so that blob response turn to json
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (
            error.request.responseType === 'blob' &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf('json') !== -1
        ) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader()
                reader.onload = () => {
                    error.response.data = JSON.parse(reader.result);
                    resolve(Promise.reject(error));
                };
                reader.onerror = () => {
                    reject(error);
                };
                reader.readAsText(error.response.data);
            })
        }
        return Promise.reject(error);
    }
);

/**
 * Register window innerHeight as a global variable
 * Then we set the value in the --vh custom property to the root of the document
 * We listen to the resize event
 * We execute the same script as before
 */
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

function RedirectToLogin() {
    window.location.href = '/login';
    return null;
}

function App(props) {
    useEffect(() => {
        store.dispatch(loginUserFromLastSession());
    }, []);

    return <>
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <QueryClientProvider client={queryClient}>
                        <ErrorBoundary>
                            <FeedBack/>

                            <NavBar/>

                            <Suspense fallback={<LoadingPage/>}>
                                <Switch>
                                    <WithSmithAIRoute exact path="/" component={RedirectToLogin} />
                                    <Route exact path="/errorpage" component={ErrorPage} />
                                    <WithoutSmithAIRoute path="/forgetpassword"  component={ForgetPasswordPage}/>
                                    <TokenRoute exact path="/resetpassword/:token" sub="rsp" component={ResetPasswordPage}/>
                                    <AuthRoute exact path="/login" component={LoginPage} />
                                    {/* <LoginRequriedRoute exact path="/crf" component={CRFFormPage} /> */}
                                    <LoginRequriedRoute exact path="/crf/:protocolId" component={CRFFormPage} />
                                    <LoginRequriedRoute exact path="/portal" component={PortalHomePage} />
                                    <LoginRequriedRoute exact path="/ecrfFlatFile" component={ECrfFlatFile} />
                                    <LoginRequriedRoute exact path="/accountsettings" component={AccountSettingsPage} />
                                    <LoginRequriedRoute exact path="/editInstitution" component={EditInstitutionPage} />
                                    <LoginRequriedRoute exact path="/usermanagement" component={UserManagementPage}/>
                                    <LoginRequriedRoute exact path="/clinicalProtocols" component={ClinicalProtocolsManagementPage}/>
                                    <LoginRequriedRoute exact path="/adminOrgs" component={AdminInstitutionPage}/>
                                    <LoginRequriedRoute exact path="/dailyPatients" component={DailyPatientsPage}/>
                                    <LoginRequriedRoute exact path="/messages" component={MessagesPage}/>
                                    <Route component={PageNotFound} />
                                </Switch>
                            </Suspense>
                        </ErrorBoundary>

                        <Footer/>

                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </LocalizationProvider>

                <IdleTimer />
            </Provider>
        </MuiThemeProvider>
    </>;
}

const WR = withRouter(App);
const AppWithRouter = () =>(<BrowserRouter><WR/></BrowserRouter>);

export default AppWithRouter;
