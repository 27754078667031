import  { useState, cloneElement, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import MuiHomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CssBaseline from '@mui/material/CssBaseline';
import withStyles from '@mui/styles/withStyles';
import Hidden from '@mui/material/Hidden';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { logoutUser } from '../redux/actions/userAction'
import {
    HomeIcon,
    ProfileIcon,
} from '../components/Icons';

function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return cloneElement(children, { elevation: trigger ? 1 : 0 });
}

const styles = (theme) => ({
    ...theme.customStyle,
    navBarTitle: {
        textAlign: 'center',
        flexGrow: 4,
    },
    navBar: {
        width: '95%',
        maxWidth: '1250px',
        margin: 'auto',
        padding: 0,
        minHeight: '45px',
        backgroundColor: '#dcff57',
    },
    navBarLink: {
        flexGrow: 0,
        borderRadius: 20,
        margin: '0 1px 0 1px',
        padding: '5px 20px 5px 20px',
        color: '#0b002b',
        textTransform:'none',
        transform: 'translate(0,0)',
        textDecoration: 'none',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)',
            transform: 'translate(0,-1px)',
        },
    },   
});

const UserMenu = ({ user, menuAnchor, handleMenuClose, logoutUser }) => {
    return <>
        <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
            }}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
            }}
            style={{ zIndex: 1201 }}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 28,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                    },
                },
            }}>

            <MenuItem component={Link} to={'/portal'} onClick={handleMenuClose}>
                <ListItemIcon>
                    <MuiHomeIcon fontSize="small" />
                </ListItemIcon>
                Portal Home
            </MenuItem>

            <Divider />

            <MenuItem
                component={Link}
                to={'/accountsettings'}
                onClick={handleMenuClose}>
                <ListItemIcon>
                    <PersonIcon  fontSize="small" />
                </ListItemIcon>
                User Settings
            </MenuItem>

            <Divider />

            <MenuItem onClick={() => { handleMenuClose(); logoutUser() }}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                Log Out
            </MenuItem>
        </Menu>
    </>
}

function NavBar(props) {
    const { classes, user, logoutUser } = props;

    const [state, setState] = useState(false);

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState(!state);
    };

    const [menuAnchor, setMenuAnchor] = useState(null);
    const navItems = [['HOME', HomeIcon, '/portal']];

    const navBarItems = navItems.map(([text, Icon, link], index) => {
        return <Button
            key={`navBarItems-${text}-${index}`}
            className={classes.navBarLink}
            component={Link}
            to={'/portal'}
            color="inherit">
                <Typography
                    varient="h6"
                    style={{ textTransform: 'none' }}>
                        {text}
                </Typography>
        </Button>
    });

    const menuItems = (
        <div onClick={toggleDrawer} onKeyDown={toggleDrawer}>
            <List>
                <Toolbar />

                {navItems.map(([text, Icon, link], idx) => {
                    return (
                        <Fragment key={text}>
                            <ListItem style={{ width: '100%' }}>
                                <Button
                                    className={classes.navBarLink}
                                    style={{ width: '100%', justifyContent: 'left' }}
                                    color="inherit"
                                    component={Link}
                                    to={link}
                                    startIcon={<Icon fill="#000" />}>
                                        <Typography varient="h6" style={{ textTransform: 'none' }}>
                                            {text}
                                        </Typography>
                                </Button>
                            </ListItem>
                        </Fragment>
                    )
                })}
            </List>
        </div>
    );

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const portalButtonProps = user.authenticated
        ? { onClick: (e) => setMenuAnchor(e.currentTarget),}
        : { component: Link, to: '/login', };

    return <>
        <CssBaseline />
        <ElevationScroll {...props}>
            <AppBar style={{ zIndex: 1201 }}>
                <Toolbar className={classes.navBar}>
                    <Hidden mdUp>
                        <IconButton
                            className={classes.navBarLink}
                            color="inherit"
                            onClick={toggleDrawer}
                            size="large">
                            {state ? <CloseRoundedIcon /> : <MenuIcon />}
                        </IconButton>
                        <Drawer
                            anchor="top"
                            open={state}
                            onClose={toggleDrawer}>
                                {menuItems}
                        </Drawer>
                    </Hidden>

                    <Hidden mdDown>{navBarItems}</Hidden>

                    <div className={classes.navBarTitle} />

                    <Tooltip title={user.authenticated ? `Signed in as ${user.name}` : 'Portal'}>
                        <IconButton className={classes.navBarLink} {...portalButtonProps} size="large">
                            {user.authenticated ? (
                                <Avatar
                                    style={{
                                        width: 32,
                                        height: 32,
                                        margin: -4,
                                        backgroundColor: 'rgba(200, 218, 211, 0.5)',
                                        color: '#FFF',
                                    }}
                                    alt={user.name}
                                    src={user.avatarUrl || '/'}
                                />
                            ) : (
                                <ProfileIcon />
                            )}
                        </IconButton>
                    </Tooltip>

                </Toolbar>
            </AppBar>
        </ElevationScroll>

        <Toolbar className={classes.navBar}/>
        <UserMenu
            menuAnchor={menuAnchor}
            handleMenuClose={handleMenuClose}
            logoutUser={logoutUser}
            user={user}
        />
    </>;
}

NavBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = {
    logoutUser,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(NavBar));
