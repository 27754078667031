import  { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
});

const clearSmithAI = () => {
    let timeout = null;
    let time = 0;
    let interval = 50;
    const clear = () => {
        if (document.getElementById('smithChatWidget')) {
            try {
                document.getElementById('smithChatWidget').style.display = 'none';
            } catch (error) {}
        }
        time += interval;
        if (time < 3000) {
            timeout = setTimeout(clear, interval);
        }
    }
    timeout = setTimeout(clear, interval);

    return () => {
        clearTimeout(timeout);
    }
}

const verifyToken = (token, sub) => {
    // validate the token, return payload and notExpired (true or false)
    let payload;
    try {
        payload = jwtDecode('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.' + token);
        if (payload.sub !== sub || !payload.exp) {
            return { payload: null };
        }
    } catch (error) {
        return { payload: null };
    }

    // expired is false if there is no payload.
    const expired = payload.exp * 1000 < Date.now(); // true

    return {
        payload,
        expired,
    };
}

const AuthRoute = ({ component: Component, authenticated, ...rest }) => {
    useEffect(clearSmithAI, []);

    // use the following line to redirect to the url after login.
    const next = (rest.location && rest.location.state && rest.location.state.nextUrl ) || '/portal';

    return (
        <Route
            {...rest}
            render={(props) => authenticated === true ? <Redirect to={next} /> : <Component {...props} />}
        />
    )
}

/**
 * Redirect user to login page when visiting this route.
 * the path of route is passed to login page as part of the state object..
 */
const LoginRequriedRoute = connect(mapStateToProps)(
    ({ component: Component, authenticated, ...rest }) => {
        useEffect(clearSmithAI, []);
        return (
            <Route
                {...rest}
                render={(props) =>
                    authenticated === true ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { nextUrl: rest.path},
                            }}
                        />
                    )
                }
            />
        )
    }
)

/**
High order component,
take token and sub, and verify token's sub field is the same as sub.
if token is expired, redirect to expired page.
if token is invalid, redirect to page not found.
two additional properties are passed to child component if the token is valid:
token and tokenPayload
token is the token string without header,
tokenPayload is the decoded payload.
*/
const TokenRoute = (props) => {
    const { component: Component, sub, ...rest } = props;
    const token = props.computedMatch.params.token;

    // const token = props.path.split('/')[1]
    let { payload, expired } = verifyToken(token, sub);
    useEffect(clearSmithAI, []);

    return (
        <Route
            {...rest}
            render={(props) =>
                payload ? (
                    expired ? (
                        <Redirect to="/pageexpired" />
                    ) : (
                        <Component
                            tokenPayload={payload}
                            token={token}
                            {...props}
                        />
                    )
                ) : (
                    <Redirect to="/pagenotfound" />
                )
            }
        />
    )
}

const WithSmithAIRoute = (props) => {
    const { component: Component, ...rest } = props;

    useEffect(() => {
        const smithChatBlock = document.getElementById('smithChatWidget');
        if (smithChatBlock) {
            if (smithChatBlock) {
                smithChatBlock.style.display = 'block'
            }
            return () => {
                smithChatBlock.style.display = 'none'
            }
        }
    }, []);
    return <Route {...rest} render={(props) => <Component {...props} />} />
}

const WithoutSmithAIRoute = (props) => {
    const { component: Component, ...rest } = props;
    useEffect(clearSmithAI, []);
    return <Route {...rest} render={(props) => <Component {...props} />} />
}

export {
    TokenRoute,
    WithSmithAIRoute,
    WithoutSmithAIRoute,
    LoginRequriedRoute,
}

export default connect(mapStateToProps)(AuthRoute);
