import {
    SET_USER,
    RESET_ALL_STATE,
    LOADING_USER,
    SET_CONTACT_LETTER,
    SET_UNAUTHENTICATED,
} from '../types';

const initialState = {
    authenticated: false,
    requireMFAToken: false,
    mfaMethod: '',
    loading: false,
    orgAbbvName: '',
    savePerferenceStatus: {
        loading: false,
        error: false,
        success: false,
    },
    contactLetter: {
        loading: false,
        sent: false,
    }, // for contact up page form
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CONTACT_LETTER:
            return {
                ...state,contactLetter: {
                    ...state.contactLetter,
                    ...action.payload,
                }
            }
        case RESET_ALL_STATE:
        case SET_UNAUTHENTICATED:
            return initialState
        case SET_USER:
            return {
                ...state,
                authenticated: true,
                loading: false,
                ...action.payload,
            }
        case LOADING_USER:
            return {
                ...state,
                loading: true,
            }
        default:
            return state;
    }
}
