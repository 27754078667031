import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';

const styles = (theme) => ({
    ...theme.customStyle,
    footerLink: {
        fontSize: 15,
        fontFace: 'Mark',
        fontWeight: 'normal',
        color:'#0b002b',
        textAlign: 'right',
        margin: '20px auto',
        paddingBottom: '30px',
        '& a': {
            margin: '0px 0.5em',
            textDecoration: 'none',
            color: 'inherit',
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
            },
        },
    },
});

function Footer(props) {
    const { classes } = props;

    const version = process.env.REACT_APP_VERSION || "";

    return (
        <div className="footer-container">
            <div className="container footerized">
                <div className="logo"></div>
                <div className={classes.footerLink}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={10}>
                            &nbsp;
                            <span style={{color: 'white'}}>
                                {version}
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            &copy; cencallabs, {(new Date()).getFullYear()}.
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

export default withStyles(styles)(Footer);
