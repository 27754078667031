import {
    createStore,
    combineReducers,
    applyMiddleware,
    compose,
} from 'redux';
import thunk from 'redux-thunk';
import userReducer from './reducers/userReducer';
import uiReducer from './reducers/uiReducer';

const initialState = {};
const middleware = [thunk];

const reducers = combineReducers({
    user: userReducer,
    UI: uiReducer,
})

// check if user have redux tool
let reduxTool = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store = createStore(
    reducers,
    initialState,
    reduxTool
        ? compose(applyMiddleware(...middleware), reduxTool)
        : compose(applyMiddleware(...middleware))
);

export default store;
