import  { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../redux/actions/userAction';
import ConfirmDialog from './ConfirmDialog';
import { useIdleTimer } from 'react-idle-timer';

const Timer = (props) => {
    const { user, logoutUser } = props;
    const LagTime = 30; // how many seconds user need to respond after dialog opened.
    const [open, setOpen] = useState(false);
    const [remaining, setRemaining] = useState(LagTime);

    const handleDialogClose = () => {
        setOpen(false);
        setRemaining(LagTime);
    };

    const handleLogout = () => {
        logoutUser();
        setOpen(false);
    };

    const handleOnIdle = (e) => {
        if (Date.now() - getLastActiveTime() > LagTime * 1000) {
            logoutUser();
            return;
        }

        if (!open) {
            setOpen(true);
        }
    }

    // set timeout value according to user role, default 10minutes
    const timeout = {
        admin: 60 * 60 * 1000,
        reviewer: 30 * 60 * 1000,
        user: 4 * 60 * 60 * 1000,
    }[user.role] || 60 * 60 * 1000;

    const { getLastActiveTime } = useIdleTimer({
        timeout: timeout,
        onIdle: handleOnIdle,
        debounce: timeout / 10,
    });

    // check if the dialog is open and check remaining time for idle counter.
    useEffect(() => {
        let timeout;
        if (remaining < 1 && open) {
            setOpen(false);
            logoutUser();
        } else if (open) {
            timeout = setTimeout(() => setRemaining((re) => re - 1), 1000);
        }

        return () => {
            clearTimeout(timeout);
        }
    }, [open, remaining, logoutUser]);

    return (
        <div>
            <ConfirmDialog
                open={open}
                handleClose={handleDialogClose}
                handleConfirm={handleLogout}
                title="You have been idle!"
                confirmText="Log Out"
                cancelText="Stay"
            >
                You have been idle for more than {Math.round(timeout / 60000)} minutes.
                <br/>
                Your session will time out in {remaining} seconds. Do you want
                to stay?
            </ConfirmDialog>
        </div>
    )
};

export const IdleTimer = (props) => {
    const { user, logoutUser } = props;
    return user.authenticated ? <Timer user={user} logoutUser={logoutUser} /> : null;
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = {
    logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(IdleTimer);
